import {
  blueGrey,
  grey,
  lightBlue,
  lightGreen,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from "@mui/material/colors";

export const MethodCategories = {
  DIY: "DIY / 3D Printed",
  DT: "Dual Tension",
  Inflation: "Inflation",
  Manual: "Manual",
  Other: "Other",
  Retaining: "Retaining",
  Taping: "Taping",
  Traction: "Traction",
  Traditional: "Traditional Tugger",
  Weights: "Weights",
};

export const MethodColors = {};
MethodColors[MethodCategories.DIY] = pink;
MethodColors[MethodCategories.DT] = red;
MethodColors[MethodCategories.Inflation] = lightGreen;
MethodColors[MethodCategories.Manual] = lightBlue;
MethodColors[MethodCategories.Other] = blueGrey;
MethodColors[MethodCategories.Retaining] = yellow;
MethodColors[MethodCategories.Taping] = orange;
MethodColors[MethodCategories.Traction] = grey;
MethodColors[MethodCategories.Traditional] = purple;
MethodColors[MethodCategories.Weights] = teal;

export const AllMethods = [
  // --- DUAL TENSION METHODS ---
  { key: "4restore", name: "4restore", type: MethodCategories.DT },
  { key: "CAT II Q", name: "CAT II Q", type: MethodCategories.DT },
  { key: "DILE Insert", name: "DILE Insert", type: MethodCategories.DT },
  {
    key: "Dual Tension Rod",
    name: "Dual Tension Rod",
    type: MethodCategories.DT,
  },
  { key: "DTR", name: "Dual Tension Rod", type: MethodCategories.DT },
  { key: "Forenew", name: "Forenew", type: MethodCategories.DT },
  { key: "Happy Aardvark", name: "Happy Aardvark", type: MethodCategories.DT },
  { key: "HART", name: "Happy Aardvark", type: MethodCategories.DT },
  { key: "Mantor", name: "Mantor Restorer", type: MethodCategories.DT },
  {
    key: "Mantor Restorer",
    name: "Mantor Restorer",
    type: MethodCategories.DT,
  },
  { key: "Stamen E", name: "Stamen E", type: MethodCategories.DT },
  { key: "TLC-X", name: "TLC-X", type: MethodCategories.DT },

  // --- INFLATION METHODS ---
  { key: "Airforce", name: "Airforce", type: MethodCategories.Inflation },
  {
    key: "Chris' Air Retainer",
    name: "Chris' Air Retainer",
    type: MethodCategories.Inflation,
  },
  {
    key: "Chris Air Retainer",
    name: "Chris' Air Retainer",
    type: MethodCategories.Inflation,
  }, // ADDED
  {
    key: "CAR-1",
    name: "Chris' Air Retainer",
    type: MethodCategories.Inflation,
  },
  {
    key: "Chris Air Retainer (CAR-1)",
    name: "Chris' Air Retainer",
    type: MethodCategories.Inflation,
  },
  {
    key: "DTR with Direct Air",
    name: "DTR with Direct Air",
    type: MethodCategories.Inflation,
  },
  {
    key: "DTR with Air",
    name: "DTR with Direct Air",
    type: MethodCategories.Inflation,
  },
  {
    key: "Direct Air",
    name: "DTR with Direct Air",
    type: MethodCategories.Inflation,
  },
  {
    key: "DTR with Indirect Air",
    name: "DTR with Indirect Air",
    type: MethodCategories.Inflation,
  },
  {
    key: "Indirect Air",
    name: "DTR with Indirect Air",
    type: MethodCategories.Inflation,
  },
  {
    key: "Inflation",
    name: "DTR with Indirect Air",
    type: MethodCategories.Inflation,
  }, // ADDED
  { key: "FIT v2", name: "FIT v2", type: MethodCategories.Inflation },
  { key: "FIT v3", name: "FIT v3", type: MethodCategories.Inflation },
  { key: "FIT v4", name: "FIT v4", type: MethodCategories.Inflation },
  {
    key: "Foreskinned Air",
    name: "Foreskinned Air",
    type: MethodCategories.Inflation,
  },
  {
    key: "HyperRestore Direct Air",
    name: "HyperRestore Direct Air",
    type: MethodCategories.Inflation,
  },
  {
    key: "HyperRestore Original",
    name: "HyperRestore Original",
    type: MethodCategories.Inflation,
  },
  {
    key: "PeckerPacker",
    name: "PeckerPacker",
    type: MethodCategories.Inflation,
  },
  { key: "Priva Air", name: "Priva Air", type: MethodCategories.Inflation },
  {
    key: "TLC Air Devices",
    name: "TLC Air Devices",
    type: MethodCategories.Inflation,
  },

  // --- MANUAL METHODS ---
  {
    key: "Andre's Method",
    name: "Andre's Method",
    type: MethodCategories.Manual,
  },
  {
    key: "Andres Method",
    name: "Andre's Method",
    type: MethodCategories.Manual,
  },
  {
    key: "Andres_Method",
    name: "Andre's Method",
    type: MethodCategories.Manual,
  },
  { key: "Manual", name: "Manual", type: MethodCategories.Manual },
  { key: "tugging", name: "Manual", type: MethodCategories.Manual },
  { key: "MM1", name: "Manual Method 1", type: MethodCategories.Manual },
  {
    key: "Manual Method 1",
    name: "Manual Method 1",
    type: MethodCategories.Manual,
  },
  { key: "manual 1", name: "Manual Method 1", type: MethodCategories.Manual },
  { key: "MM2", name: "Manual Method 2", type: MethodCategories.Manual },
  {
    key: "Manual Method 2",
    name: "Manual Method 2",
    type: MethodCategories.Manual,
  },
  { key: "manual 2", name: "Manual Method 2", type: MethodCategories.Manual },
  { key: "MM3", name: "Manual Method 3", type: MethodCategories.Manual },
  {
    key: "Manual Method 3",
    name: "Manual Method 3",
    type: MethodCategories.Manual,
  },
  { key: "manual 3", name: "Manual Method 3", type: MethodCategories.Manual },
  { key: "MM4", name: "Manual Method 4", type: MethodCategories.Manual },
  {
    key: "Manual Method 4",
    name: "Manual Method 4",
    type: MethodCategories.Manual,
  },
  { key: "manual 4", name: "Manual Method 4", type: MethodCategories.Manual },
  { key: "MM5", name: "Manual Method 5", type: MethodCategories.Manual },
  {
    key: "Manual Method 5",
    name: "Manual Method 5",
    type: MethodCategories.Manual,
  },
  { key: "manual 5", name: "Manual Method 5", type: MethodCategories.Manual },
  { key: "MM6", name: "Manual Method 6", type: MethodCategories.Manual },
  {
    key: "Manual Method 6",
    name: "Manual Method 6",
    type: MethodCategories.Manual,
  },
  { key: "manual 6", name: "Manual Method 6", type: MethodCategories.Manual },
  { key: "MM7", name: "Manual Method 7", type: MethodCategories.Manual },
  {
    key: "Manual Method 7",
    name: "Manual Method 7",
    type: MethodCategories.Manual,
  },
  { key: "manual 7", name: "Manual Method 7", type: MethodCategories.Manual },

  // --- TAPING METHODS ---
  {
    key: "Canister Method",
    name: "Canister Method",
    type: MethodCategories.Taping,
  },
  {
    key: "Modified T-Taping/Cross-Taping",
    name: "Modified T-Taping/Cross-Taping",
    type: MethodCategories.Taping,
  },
  {
    key: "MTC",
    name: "Modified T-Taping/Cross-Taping",
    type: MethodCategories.Taping,
  },
  {
    key: "SuperCanister",
    name: "SuperCanister",
    type: MethodCategories.Taping,
  },
  { key: "T-Taping", name: "T-Taping", type: MethodCategories.Taping },
  {
    key: "Reverse Taping Method",
    name: "Reverse Taping Method",
    type: MethodCategories.Taping,
  },
  {
    key: "RTM",
    name: "Reverse Taping Method",
    type: MethodCategories.Taping,
  },
  {
    key: "Reverse_Taping_Method",
    name: "Reverse Taping Method",
    type: MethodCategories.Taping,
  }, // ADDED

  // --- TRADITIONAL METHODS ---
  {
    key: "Chris' tugger",
    name: "Chris' tugger",
    type: MethodCategories.Traditional,
  },
  {
    key: "Foreskin Mitosis Device",
    name: "Foreskin Mitosis Device",
    type: MethodCategories.Traditional,
  },
  {
    key: "FMD",
    name: "Foreskin Mitosis Device",
    type: MethodCategories.Traditional,
  },
  { key: "Foreclip", name: "Foreclip", type: MethodCategories.Traditional },
  {
    key: "Foreskinned Workhorse",
    name: "Foreskinned Workhorse",
    type: MethodCategories.Traditional,
  },
  { key: "Kynodesme", name: "Kynodesme", type: MethodCategories.Traditional },
  {
    key: "Restore in Comfort",
    name: "Restore in Comfort",
    type: MethodCategories.Traditional,
  }, // ADDED
  {
    key: "RIC",
    name: "Restore in Comfort",
    type: MethodCategories.Traditional,
  }, // ADDED
  { key: "TLC Tugger", name: "TLC Tugger", type: MethodCategories.Traditional },
  { key: "TLC", name: "TLC Tugger", type: MethodCategories.Traditional },
  { key: "TLC_Tugger", name: "TLC Tugger", type: MethodCategories.Traditional },

  // --- RETAINING METHODS ---
  { key: "Retainer", name: "Retainer", type: MethodCategories.Retaining },
  { key: "O-Rings", name: "O-Rings", type: MethodCategories.Retaining },
  // Aliases for "Retainer":
  {
    key: "Mantor Retainer",
    name: "Retainer",
    type: MethodCategories.Retaining,
  }, // ADDED
  {
    key: "Chris Silicone Foreskin Retainer",
    name: "Retainer",
    type: MethodCategories.Retaining,
  }, // ADDED
  { key: "DTR Retainer", name: "Retainer", type: MethodCategories.Retaining }, // ADDED
  { key: "retaining", name: "Retainer", type: MethodCategories.Retaining }, // ADDED
  {
    key: "HyperRestore Retainer",
    name: "Retainer",
    type: MethodCategories.Retaining,
  }, // ADDED

  // --- WEIGHTS METHODS ---
  {
    key: "DTR with weights",
    name: "DTR with weights",
    type: MethodCategories.Weights,
  },
  { key: "Foreballs", name: "Foreballs", type: MethodCategories.Weights },
  { key: "Foreballer", name: "Foreballs", type: MethodCategories.Weights },
  {
    key: "Foreskinned Gravity",
    name: "Foreskinned Gravity",
    type: MethodCategories.Weights,
  },
  { key: "Stamen D", name: "Stamen D", type: MethodCategories.Weights },
  {
    key: "Stealth Retainers Weight Kits",
    name: "Stealth Retainers Weight Kits",
    type: MethodCategories.Weights,
  },
  // Aliases for "Stealth Retainers Weight Kits":
  {
    key: "Stealth Retainer size 12 L standard grip",
    name: "Stealth Retainers Weight Kits",
    type: MethodCategories.Weights,
  }, // ADDED
  {
    key: "Stealth Retainer 14 XL  snug grip",
    name: "Stealth Retainers Weight Kits",
    type: MethodCategories.Weights,
  }, // ADDED
  {
    key: "Stealth Retainer 14 xl standard grip",
    name: "Stealth Retainers Weight Kits",
    type: MethodCategories.Weights,
  }, // ADDED
  {
    key: "Stealth Retainer 14 xl steel",
    name: "Stealth Retainers Weight Kits",
    type: MethodCategories.Weights,
  }, // ADDED
  {
    key: "Stealth Retainers",
    name: "Stealth Retainers Weight Kits",
    type: MethodCategories.Weights,
  }, // ADDED
  {
    key: "SR xl extended 1",
    name: "Stealth Retainers Weight Kits",
    type: MethodCategories.Weights,
  }, // ADDED
  { key: "The PUD", name: "The PUD", type: MethodCategories.Weights },
  { key: "PUD", name: "The PUD", type: MethodCategories.Weights }, // ADDED
  { key: "TLC Hanger", name: "TLC Hanger", type: MethodCategories.Weights },

  // --- DIY / 3D PRINTED METHODS ---
  {
    key: "Electronic Tugger",
    name: "Electronic Tugger",
    type: MethodCategories.DT,
  },
  {
    key: "Electronic",
    name: "Electronic Tugger",
    type: MethodCategories.DT,
  },
  { key: "Homemade DTR", name: "Homemade DTR", type: MethodCategories.DT }, // ADDED
  { key: "MetaMoreFore", name: "MetaMoreFore", type: MethodCategories.DIY }, // ADDED

  // --- TRACTION METHODS ---
  {
    key: "Penimaster Pro",
    name: "Penimaster Pro",
    type: MethodCategories.Traction,
  }, // ADDED

  // --- OTHER METHODS ---
  { key: "Tug Ahoy", name: "Tug Ahoy", type: MethodCategories.Other },
  { key: "MySkinClamp", name: "MySkinClamp", type: MethodCategories.Other },
  // Aliases for "Other":
  { key: "Other", name: "Other", type: MethodCategories.Other }, // ADDED
  {
    key: "Dual Tension Rod (DTR)/Tugging",
    name: "Other",
    type: MethodCategories.Other,
  }, // ADDED
  {
    key: "Dual Tension Rod (DTR), T-taping",
    name: "Other",
    type: MethodCategories.Other,
  }, // ADDED
];
