// assets
import {
  IconBarbell,
  IconPhotoHexagon,
  IconHistory,
  IconTargetArrow,
  IconFileZip,
  IconUser,
} from "@tabler/icons-react";

const main = {
  id: "main",
  title: "Your Journey",
  type: "group",
  children: [
    {
      id: "tug",
      title: "Tug",
      type: "item",
      url: "/tug",
      icon: IconBarbell,
      breadcrumbs: false,
    },
    {
      id: "goals",
      title: "Goals [Planned]",
      type: "item",
      url: "/goals",
      icon: IconTargetArrow,
      breadcrumbs: false,
      disabled: true,
      visible: false,
    },
    {
      id: "history",
      title: "History",
      type: "item",
      url: "/history",
      icon: IconHistory,
      breadcrumbs: false,
    },
    {
      id: "albums",
      title: "Albums",
      type: "item",
      url: "/albums",
      icon: IconPhotoHexagon,
      breadcrumbs: false,
    },
    {
      id: "backup",
      title: "Your Data",
      type: "item",
      url: "/backup",
      icon: IconFileZip,
      breadcrumbs: false,
    },
    {
      id: "profile",
      title: "Profile",
      type: "item",
      url: "/profile",
      icon: IconUser,
      breadcrumbs: false,
    },
  ],
};

export default main;
