import _ from "lodash";
import { AllMethods } from "utils/methods";
import { isValidString } from "utils/misc";

class Method {
  static COLLECTION = "methods";

  constructor(data = {}) {
    this.key = _.get(data, "key");
    this.name = _.get(data, "name");
    this.type = _.get(data, "type", "Other");
  }

  get category() {
    return this.type;
  }

  static generic() {
    return _.map(AllMethods, (m) => new Method(m));
  }

  static get(name) {
    const matched = _.find(
      AllMethods,
      (m) =>
        _.get(m, "key", "").toLowerCase() === (name || "").toLowerCase() ||
        _.get(m, "name", "").toLowerCase() === (name || "").toLowerCase()
    );
    if (matched) {
      return new Method(matched);
    } else if (isValidString(name)) {
      return new Method({ name: name });
    } else {
      return new Method();
    }
  }

  get publicKey() {
    return this.name;
  }
}

export default Method;
